import * as React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Device from "../components/media/Device";
import { Seo } from "../components/seo";

const NotFoundPage = () => {
  return (
    <Layout>
      <Styled404>
        <h1>404</h1>
        <p>お探しのページが見つかりません。</p>
        <p>
          アクセスの集中、または、入力したURLが間違っている可能性があります。
        </p>
      </Styled404>
    </Layout>
  );
};
const Styled404 = styled.div`
  padding: 20rem 5vw 0;
  text-align: center;

  h1 {
    font-size: 9rem;
    font-weight: bold;
    color: var(--sub-color);
    margin-bottom: 2rem;
    @media ${Device.tablet} {
      font-size: 12.8rem;
    }
  }
  p {
    font-size: 1.5rem;
    line-height: 3.2rem;
    @media ${Device.tablet} {
      font-size: 1.6rem;
    }
  }
`;
export default NotFoundPage;

export const Head = () => <Seo title={"404"} />;
