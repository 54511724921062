import React from "react";
import useSiteMetadata from "../hooks/use-site-metadata";

export const Seo = ({ title, description, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    // image,
    siteUrl,
    // twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    // image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    // twitterUsername,
  };

  return (
    <>
      {/* <link rel="stylesheet" href="../style.css" /> */}
      <title>{seo.title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, viewport-fit=cover"
      />
      {/* <meta charset="utf-8" /> */}

      <meta name="format-detection" content="address=no,telephone=no" />
      <meta name="description" content={seo.description} />
      {/* <meta name="image" content={seo.image} /> */}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content="../images/top.png" />
      <meta name="twitter:creator" content={seo.twitterUsername || ""} />

      {/* Facebook */}
      <meta property="og:locale" content="ja_JP"></meta>
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:site_name" content="和菓子 ~kuu~" />
      <meta property="og:image" content="../images/top.png" />
      {/* <link
        rel="icon"
        href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>"
      /> */}

      {children}
    </>
  );
};
