import React from "react";
import styled from "styled-components";
import bgImage from "../images/bg80.png";
import Header from "./section/Header";
import MobileMenu from "./MobileMenu/MobileMenu";
import ToTopButton from "./parts/ToTopButton";
import Footer from "./section/Footer";
import Device from "./media/Device";
// import PageTransitionAnimation from "./PageTransitionAnimation";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <MobileMenu />
      <StyledMain>
        {children}
        <Footer />
      </StyledMain>
      <ToTopButton />
    </>
  );
};

const StyledMain = styled.main`
  background-image: url(${bgImage});
  background-position: center center;
  background-repeat: repeat;
  background-size: 200%;
  @media ${Device.tablet} {
    background-size: 120%;
  }
  @media ${Device.laptop} {
    background-size: 70%;
  }
`;

export default Layout;
